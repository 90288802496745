import React from "react";
import "./plans.css";
import { plans } from "../../data";
import Header from "../../components/Header";
import plan from "../../images/plan.jpg";
import Card from "../../UI/Card";

const Plans = () => {
	return (
		<>
			<Header title="Membership Plans" image={plan}>
			We understand that everyone likes simple, so that's exactly how our prices work. With NO joining or induction fees, the only thing left to do is train. Let us do what we do best and turn your expectations into reality. With the benefits of professional training and tailored planning, we know you'll surpass your fitness goals.
			</Header>
			<section className="plans">
				<div className="container plans__container">
					{plans.map(({ id, name, desc, price, features }) => {
						return (
							<Card key={id} className="plan">
								<h3>{name}</h3>
								<small>{desc}</small>
								<h1>{`$ ${price}`}</h1>
								<h2>/mo</h2>
								<h4>Features</h4>
								{features.map(({ feature, available, index }) => {
									return (
										<p key={index} className={available ? "" : "disabled"}>
											{feature}
										</p>
									);
								})}
								<button className="btn lg">Choose Plan</button>
							</Card>
						);
					})}
				</div>
			</section>
		</>
	);
};

export default Plans;
