import {CgGym} from 'react-icons/cg';
import {BiRun} from 'react-icons/bi';
import {FaFire} from 'react-icons/fa';
import {TbHeartbeat} from 'react-icons/tb'
import {TiTick} from 'react-icons/ti'

export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Gallery",
        path: '/gallery'
    },
    {
        name: "Plans",
        path: '/plans'
    },
    {
        name: "Trainers",
        path: '/trainers'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]







export const programs = [
    {
        id: 1,
        icon: <CgGym/>,
        title: "Strength Training",
        info: "In this program, you are trained to improve your strength through many exercises.",
        path: "/programs/111"
    },
    {
        id: 2,
        icon: <BiRun/>,
        title: "Cardio Training",
        info: "In this program, you are trained to do sequential moves in range of 20 until 30 minutes.",
        path: "/programs/222"
    },
    {
        id: 3,
        icon: <FaFire/>,
        title: "Fat Burning",
        info: "This program is suitable for you who wants to get rid of your fat and lose their weight.",
        path: "/programs/333"
    },
    {
        id: 4,
        icon: <TbHeartbeat/>,
        title: "Health Fitness",
        info: "This programs is designed for those who exercises only for their body fitness not body building.",
        path: "/programs/444"
    }
]








export const values = [
    {
        id: 1,
        icon: <TiTick/>,
        title: "Reason One",
        desc: "Over 140+ EXPERT COACHS."
    },
    {
        id: 2,
        icon: <TiTick/>,
        title: "Reason Two",
        desc: "TRAIN SMARTER AND FASTER THAN BEFORE"
    },
    {
        id: 3,
        icon: <TiTick/>,
        title: "Reason Three",
        desc: "1 FREE PROGRAM FOR NEW MEMBER"
    },
    {
        id: 4,
        icon: <TiTick/>,
        title: "Reason Four",
        desc: "RELIABLE PARTNERS"
    }
]









export const faqs = [
    {
        id: 1,
        question: "What is your heaviest dumbell?",
        answer: "Dumbells go from 1kg to 60kg.....and we have LOTS of them!!"
    },
    {
        id: 2,
        question: "If I have a health issue,Can i still do Gym?",
        answer: "Yes,but it will depend on your condition and medical clearance may need to be provided by your doctor. We have specialised staff that can help you and members are regularly referred to us by their doctor. If you have a specific medical issue, please email the details in confidence to fitclub@gmail.com and attach a copy of your doctor/physio clearance letter to this email."
    },
    {
        id: 3,
        question: "Is there a sauna & Changing rooms?",
        answer: "Yes we have seperate male and female changing rooms with saunas in each for added comfort. We provide showers and lockers inside the changing rooms. Members use the lockers only when in the building and a padlock is required for locking. You can bring your own or we sell padlocks in our vending machines. Shower gels or shampoo are not provided in the showers."
    },
    {
        id: 4,
        question: "What age do I need to be to join Gym?",
        answer: "Due to insurance reasons, you must be 16 years old before becoming a member. Outside of that, you’re welcome at any age - because you're never too old to keep fit and active.!"
    },
    {
        id: 5,
        question: "Do I Need to Work Out Every Day?",
        answer: "No, you do not need to work out every day. In fact, in most cases, I would recommend at least 1-2 days of total rest a week. However, just because you have a rest or recovery day scheduled into your calendar, doesn’t necessarily mean you aren’t active at all on these days. Light, regular movement such as walking your dog around the block, or taking time for stretching or foam rolling are great recovery techniques to help increase blood flow and reduce tension in tight muscles."
    },
    {
        id: 6,
        question: "What Training Should I Be Doing to Lose Weight?",
        answer: "Regular cardio training is great for increasing your daily calorie burn. While strength training will help you build lean muscle, which will increase your metabolism and help you burn more calories at rest!."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Diana Ayi",
        quote: "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
        job: "Student",
        avatar: require("./images/avatar1.jpg")
    },
    {
        id: 2,
        name: "Daniel Vinyo",
        quote: "The atmosphere at FitClub Gym is amazing, the people are even better. All in all, it is a great place to work out.",
        job: "Software Egineer",
        avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Edem Quist",
        quote: "The facilities at FitClub Gym stood out to me the most. They are well maintained and have high quality equipment.",
        job: "University Lecturer",
        avatar: require("./images/avatar3.jpg")
    },
    {
        id: 4,
        name: "Grace Lavoe",
        quote: "The trainers at FitClub Gym have helped me reach my fitness goals beyond anything I could've hoped for. They truly care.",
        job: "Entrepreneur",
        avatar: require("./images/avatar4.jpg")
    },
    {
        id: 5,
        name: "Nana Yaa Dankwa",
        quote: "No bad things to say about FitClub Gym, they are top notch at every corner and truly take care of their customers.",
        job: "Pharmacist",
        avatar: require("./images/avatar5.jpg")
    }
]







export const plans = [
    {
        id: 1,
        name: 'Silver Package',
        desc: 'This package is perfect for beginners who need constant help',
        price: 29.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: false},
            {feature: 'Sixth Feature', available: false},
            {feature: 'Seventh Feature', available: false},
            {feature: 'Seventh Feature Plus', available: false},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 2,
        name: 'Gold Package',
        desc: 'This is the perfect package for beginners who know what their doing',
        price: 49.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: false},
            {feature: 'Ninth Feature', available: false},
            {feature: 'Tenth Feature', available: false},
            {feature: 'Eleventh Feature', available: false}
        ]
    },
    {
        id: 3,
        name: 'Platinum Package',
        desc: 'This package is perfect for busy people who need home service',
        price: 89.99,
        features: [
            {feature: 'First Feature', available: true},
            {feature: 'Second Feature', available: true},
            {feature: 'Third Feature', available: true},
            {feature: 'Fourth Feature', available: true},
            {feature: 'Fifth Feature', available: true},
            {feature: 'Fifth Feature Plus', available: true},
            {feature: 'Sixth Feature', available: true},
            {feature: 'Seventh Feature', available: true},
            {feature: 'Seventh Feature Plus', available: true},
            {feature: 'Eighth Feature', available: true},
            {feature: 'Ninth Feature', available: true},
            {feature: 'Tenth Feature', available: true},
            {feature: 'Eleventh Feature', available: true}
        ]
    }
]









const Trainer1 = require('./images/trainer1.jpg')
const Trainer2 = require('./images/trainer2.jpg')
const Trainer3 = require('./images/trainer3.jpg')
const Trainer4 = require('./images/trainer4.jpg')
const Trainer5 = require('./images/trainer5.jpg')
const Trainer6 = require('./images/trainer6.jpg')


export const trainers = [
    {
        id: 1,
        image: Trainer1,
        name: 'John Doe',
        job: 'Aerobic Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: Trainer2,
        name: 'Daniel vinyo',
        job: 'Speed Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 3,
        image: Trainer3,
        name: 'Edem Quist',
        job: 'Flexibility Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 4,
        image: Trainer4,
        name: 'Shatta Wale',
        job: 'Body Composition Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 5,
        image: Trainer5,
        name: 'Diana Ayi',
        job: 'Circuit Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 6,
        image: Trainer6,
        name: 'Wayne Carter',
        job: 'Physical Intelligence Trainer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    }
]