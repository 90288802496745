import React from "react";
import "./contact.css";
import Header from "../../components/Header";
import HeaderImage from "../../images/header_bg_2.jpg";
import { MdEmail } from "react-icons/md";
import { BsMessenger } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { AiFillGithub } from "react-icons/ai";
const Contact = () => {
	return (
		<>
			<Header title="Gt In Touch" image={HeaderImage}>
			Please email us using the button provided below and a member of our team will be in touch with you soon to discuss your goals and start your fitness journey. Alternatively give us a call us on 01487 208150.<br></br>
			
			Opening Hours:<br></br>
			Mon-Fri: 07:00-22:00<br></br>
			Saturday: 07.00-12.00<br></br>
			Sunday: Closed
			</Header>

			<section className="contact">
				<div className="container contact__container">
					<div className="contact__wrapper">
						<a
							href="mailto:khansaniya425@gmail.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							<MdEmail />
						</a>
						<a
							//href="#"
							target="_blank"
							rel="noreferrer noopener"
						>
							<BsMessenger />
						</a>
						<a
							// href="#"
							target="_blank"
							rel="noreferrer noopener"
						>
							<IoLogoWhatsapp />
						</a>
						<a
							// href="#"
							target="_blank"
							rel="noreferrer noopener"
						>
							<AiFillGithub />
						</a>
					</div>
				</div>
			</section>

		</>
	);
};

export default Contact;
