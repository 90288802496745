import React from "react";
import "./about.css";
import Header from "../../components/Header";
import image2 from "../../images/image2.png";
import os from "../../images/os.jpg";
import ov from "../../images/ov.jpg";
import om from "../../images/om.jpg";

const About = () => {
	return (
		<>
			<Header title="About us" image={image2}>
			Providing Private Wellness Solutions to Communities and Beyond.
			</Header>
			<section className="about__story">
				<div className="container about__story-container">
					<div className="about__section-image">
						<img src={os} height="500px" alt="OurStoryImage" />
					</div>
					<div className="about__section-content">
						<h1>Our Story</h1>
						<p>
						The FitClub was created by owner and founder Liam Rushmer more than 10 years ago from his parents’ garage where he learned the art of bodybuilding through nutrition and exercise. Between training family and friends to build upon his knowledge and trading gym equipment, the first business named Just in gyms was born.
						</p>
						<p>
						After 2 years of training, the current home of the gym was acquired for the expansion of Just in gyms which succeeded for a further 2 years before the decision was made to share the benefits of the expansive range of equipment within the local community.
						</p>
						<p>
						Then after 2 ½ years, 000's members walking through the doors and millions of kg’s lifted, Liam made the decision to make the gym widely available for members and personal training clients alike. He combined his love for fitness and his surname “Rushmer” to create Fitness Rush, a new breed of gym.
						</p>
					</div>
				</div>
			</section>

			<section className="about__vision">
				<div className="container about__vision-container">
					<div className="about__section-content">
						<h1>Our Vision</h1>
						<p>
						Honesty is our Policy: Be under no illusion that reaching your goals will be easy. We have found the best way to encourage you to the finish line is being brutally honest with you about your progress but with your best interests at heart. We push you further and the positive results will speak for themselves.
						</p>
						<p>
						Only the Best: We have handpicked a team of specialist professionals who can take you further. Whether it be special dietary needs, specific training goals or just a caring motivator, we have you covered. Trust us to do more and take you further than ever before.
						</p>
					</div>
					<div className="about__section-image">
						<img src={ov} height="500px" alt="VisionImage" />
					</div>
				</div>
			</section>

			<section className="about__mission">
				<div className="container about__mission-container">
					<div className="about__section-image">
						<img src={om} height="500px" alt="VisionImage" />
					</div>
					<div className="about__section-content">
						<h1>Our Mission</h1>
						<p>
						Our mission is to encourage all individuals to begin, enjoy and benefit from Fitness. Exercise should be used as a tool to increase physical attributes as well as our mental state. We focus on using our exclusive personal training facilities to maximise our clients results whilst making them feel relaxed and comfortable in their environment. 
						</p>
						<p>
						You Come First: We're under no illusion that the gym wouldn't be what it is today without the support from the clients, members and colleagues, so your wellbeing and success is at the forefront of our priorities.
						We Go Further: We understand your time is precious and cannot always find time to visit us. That’s why we have put resources in place, so you are never further than a click away from your answers. Whether it be concerning nutrition, training or anything else, you can ask us.
						</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default About;
